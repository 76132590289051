<template>
  <div class="about">
    <h1>About Life in weeks</h1>
      <p>
        Hello, this is life in weeks,Here you can set your birth date and see where you are in your life (more or less).
      </p>
    <blockquote>
      "It kind of feels like our lives are made up of a countless number of weeks. But there they are—fully countable—staring you in the face."
    </blockquote>
    <figcaption>
    — Tim Urban
    </figcaption>
    <h2>Inspired by:</h2>
    <ul id="inspired-by">
      <li><a href="https://www.youtube.com/watch?v=JXeJANDKwDc">Kurzgesagt</a> </li>
      <li><a href="https://waitbutwhy.com/2014/05/life-weeks.html">Wait but why</a> </li>
    </ul>

    <h1>About Me</h1>
      <p>
        I'm a fullstack developer based in Colombia, I love educational videos, so I get really inspired by the video of Kurzgesagt.
      </p>
      <h2>Contact me:</h2>
    <ul id="inspired-by">
      <li><a href="https://www.youtube.com/watch?v=JXeJANDKwDc">Youtube</a> </li>
      <li><a href="https://github.com/fega">Github</a> </li>
      <li><a href="https://www.linkedin.com/in/fabiangutierreza/"> LinkedIn</a> </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.about{
  color: white;
}
#inspired-by{
  width: max-content;
  margin: auto;
}
a:link {
  color: aqua;
}
a:visited {
  color: darkviolet;
}
</style>
